<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }" v-if="$_isAdmin()">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dashboard' }" v-else>首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item>系统管理</el-breadcrumb-item> -->
            <el-breadcrumb-item>医院管理</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card class="box-card">
        <div class="text item">
            <div v-if="$_isMobile()">
                <el-row :gutter="20" type="flex" align="center">
                    <el-col :span="20">
                        <!--遗留bug 第二页 搜索不好使 ，新增一个查询方法，将当前页码设置为1 再调用getUserList-->
                        <el-input placeholder="请输入医院名称或登录账号" v-model="queryInfo.username" clearable @clear="getUserList">
                            <el-button slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
                <!--:picker-options="pickerOptions"--->
                <el-row :gutter="20" type="flex" align="center">
                    <el-col :span="20">
                        <el-date-picker
                            v-model="creatTime"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="selectCreatTime(creatTime)"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                
                </el-row>
                <el-row :gutter="20" type="flex" align="center">
                    <el-col :span="3">
                        <el-button type="primary" v-show="$_has('users:add')" @click="showAddDialog()">添加医院</el-button>
                    </el-col>
                
                </el-row>
            </div>
            <div v-else>
                <el-row :gutter="20" type="flex" align="center">
                <el-col :span="6">
                    <!--遗留bug 第二页 搜索不好使 ，新增一个查询方法，将当前页码设置为1 再调用getUserList-->
                    <el-input placeholder="请输入医院名称或登录账号" v-model="queryInfo.username" clearable @clear="getUserList">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
                    </el-input>
                </el-col>
                <!-- <el-col :span="4">
                    <el-select v-model="queryInfo.departmentId" placeholder="请选择科室" label="科室" clearable @change="selectDepartmentId(queryInfo.departmentId)">
                        <el-option
                            v-for="item in departmentIdScope"
                            :key="item.id"
                            :label="item.deptName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-col> -->
                <el-col :span="4">
                        <el-select v-model="queryInfo.status" placeholder="请选择状态" label="状态" clearable @change="selectStatus(queryInfo.status)">
                            <el-option label="正常" value="1">正常</el-option>
                            <el-option label="冻结" value="2">冻结</el-option>
                        </el-select>
                    </el-col>
                <el-col :span="4">
                    <el-select v-model="queryInfo.surplusCount" placeholder="请选择剩余数量" label="剩余数量" clearable @change="selectSurplusCount(queryInfo.surplusCount)">
                        <el-option label="5个以下" value="5">5个以下</el-option>
                        <el-option label="10个以下" value="10">10个以下</el-option>
                        <el-option label="50个以下" value="50">50个以下</el-option>
                        <el-option label="100个以下" value="100">100个以下</el-option>
                    </el-select>
                </el-col>
                <!--:picker-options="pickerOptions"--->
                <el-col :span="8">
                    <el-date-picker
                        v-model="creatTime"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @change="selectCreatTime(creatTime)"
                        value-format="yyyy-MM-dd"
                        >
                    </el-date-picker>
                </el-col>
                <el-col :span="3">
                    <el-button type="primary" v-show="$_has('users:add')" @click="showAddDialog()">添加医院</el-button>
                </el-col>
                
            </el-row>
            </div>
            
            <el-table :data="userList" style="width: 100%" border stripe @selection-change="handleSelectionChange"  ref="multipleTable">
                <!-- <el-table-column type="selection" label="全选" width="55"></el-table-column> -->
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="hospitalName" label="医院名称" ></el-table-column>
                <el-table-column prop="username" label="登录账号" ></el-table-column>
                <!-- <el-table-column prop="phone" label="手机号" ></el-table-column> -->
                <el-table-column prop="regionCode_dictText" label="地区" ></el-table-column>
                <!-- <el-table-column prop="roleCodeTxt" label="角色" ></el-table-column> -->
                <el-table-column prop="deptCodeTxt" label="科室" ></el-table-column>
                <el-table-column prop="total" label="总充值金额" ></el-table-column>
                <el-table-column prop="surplusCount" label="剩余线索数" ></el-table-column>
                <el-table-column prop="source" label="渠道来源" ></el-table-column>
                <el-table-column prop="createTime" label="创建时间" ></el-table-column>
                <el-table-column label="状态" >
                    <template v-slot="scope">
                        <el-switch 
                                @change="enterpriseStateChanged(scope.row)"
                                v-model="scope.row.status"
                                :active-value="1"
                                :inactive-value="2"
                                v-show="$_has('users:changestatus')"
                                >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="585px" header-align="center">
                    <template v-slot="scope">
                        <!--手动推送客户-->
                        <el-button type="success" v-show="$_has('users:hand')"  icon="el-icon-s-promotion" size="mini" @click="showHandAddDialog(scope.row.providerCode,scope.row.regionCode)">手动推送</el-button>
                        <!--充值-->
                        <el-button type="danger" v-show="$_has('users:recharge')" icon="el-icon-money" size="mini" @click="getFollowList(scope.row.providerCode)">充值</el-button>
                        <!--退款-->
                        <el-button type="warning"  icon="el-icon-money" size="mini" @click="getRefundList(scope.row.providerCode)">退款</el-button>
                        <!--修改-->
                        <el-button type="primary" v-show="$_has('users:edit')"  icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
                        <!--重置密码-->
                        <el-button type="info" v-show="$_has('users:resetpassword')"  icon="el-icon-refresh" size="mini" @click="resetPassword(scope.row.id)">重置密码</el-button>
                        <!--导出他的所有客户-->
                        <el-button type="success" v-show="$_has('users:export')"  icon="el-icon-download" size="mini" @click="exportById(scope.row.providerCode)">导出线索</el-button>
                        <!--删除-->
                        <!-- <el-button type="danger" v-show="$_has('users:detele')"  icon="el-icon-delete" size="mini" @click="removeUserById(scope.row.id)">删除</el-button> -->


                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                v-if="$_isMobile()"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.pageSize"
                layout="prev, pager, next"
                :total="total"
                pager-count="5">
            </el-pagination>
            <el-pagination
                v-else
                background
                prev-text="上一页"
                next-text="下一页"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        </el-card>


        <!--添加医院的对话框 ；async是vue1 现在vue2改为v-model-->
        <el-dialog
            :title="this.addOReditDialogVisible == 0 ? '添加医院' : '修改医院'"
            :visible.sync="addDialogVisible"
            :width="$_isMobile()?'90%':'50%'"
            @close="addDialogClosed"
            :close-on-click-modal="false">
            <!--主题内容区-->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
                <el-form-item label="医院名称" prop="hospitalName" >
                    <el-input v-model="addForm.hospitalName" placeholder="请输入医院名称"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop="username" >
                    <el-input v-model="addForm.username" placeholder="医院登录账号"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone" >
                    <el-col :span="16">
                        <el-input v-model="addForm.phone" placeholder="手机号" style="width: 100%;"></el-input>
                    </el-col>
                </el-form-item>
                
                <el-form-item label="所在地" prop="regionCodeList">
                    <!-- <el-select v-model="addForm.regionCode" placeholder="请选择地区" label="地区" clearable>
                        <el-option
                            v-for="item in cityList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select> -->
                    <el-cascader ref="cascader" v-model="addForm.regionCodeList" :options="cityList" :show-all-levels="false" :props="props" clearable @change="changeCityValue()"></el-cascader>
                </el-form-item>
                <el-form-item label="科室" prop="departmentList">
                    <!-- <el-input v-model="addForm.natureBusiness"></el-input> -->
                    
                    <el-select  v-model="addForm.departmentList" multiple placeholder="请选择科室" label="科室" clearable @change="changeDepartmentIdValue()">
                        
                        <el-option
                            v-for="item in departmentIdScope"
                            :key="item.id"
                            :label="item.deptName"
                            :value="item.id">
                        </el-option>
                    </el-select> 
                </el-form-item>
                  
                <el-form-item label="渠道来源" prop="source" >
                    <el-input v-model="addForm.source" placeholder="渠道来源"></el-input>
                </el-form-item>
                

                <el-form-item label="id" prop="id" v-show="false" hidden="true">
                    {{addForm.id}}
                </el-form-item>
            </el-form>
            <!--底部区-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" v-if="this.addOReditDialogVisible == 0" @click="addUser()">确 定</el-button>
                <el-button type="primary" v-else-if="this.addOReditDialogVisible == 1" @click="editUser()">确 定</el-button>
            </span>
        </el-dialog>

        <!--充值-->
        <el-drawer
            title="充值记录"
            :visible.sync="drawer"
            :direction="direction"
            :size="$_isMobile()?'90%':'50%'"
            @close="drawerClosed">
            <el-form :model="followForm" :rules="followFormRules" ref="followFormRef" label-width="100px">
                

                <el-form-item label="充值金额" prop="money" >
                    <el-input v-model="followForm.money" placeholder="请输入充值金额"></el-input>
                </el-form-item>
                <el-form-item label="分配线索数" prop="count" >
                    <el-input v-model="followForm.count" placeholder="请输入此次充值增加的线索数量"></el-input>
                </el-form-item>
                <!-- v-show="false" hidden="true"-->
                <!-- <el-form-item label="uId" prop="uId">
                    <el-input placeholder="请输入内容" v-model="followForm.uId" :disabled="true"></el-input>
                </el-form-item> -->
                <el-form-item label="eId" prop="eId" v-show="false" hidden="true">
                    {{queryInfo.eId}}
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addFollow(queryInfo.eId)">提交</el-button>
                </el-form-item>
            </el-form>
            

            <el-table :data="followList" style="width: 100%" border stripe  @selection-change="handleSelectionChange"  ref="multipleTable2">
                <el-table-column prop="createTime" label="充值时间" ></el-table-column>
                <el-table-column prop="money" label="充值金额" ></el-table-column>
                <el-table-column prop="count" label="分配客户数" ></el-table-column>
                <el-table-column prop="username" label="操作人" ></el-table-column>
            </el-table>

            <el-pagination
                v-if="$_isMobile()"
                background
                @size-change="followhandleSizeChange"
                @current-change="followhandleCurrentChange"
                :current-page="queryInfo.followpageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.followpageSize"
                layout="prev, pager, next"
                :total="followtotal">
            </el-pagination>
            <el-pagination
                v-else
                background
                prev-text="上一页"
                next-text="下一页"
                @size-change="followhandleSizeChange"
                @current-change="followhandleCurrentChange"
                :current-page="queryInfo.followpageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.followpageSize"
                layout="total, sizes, prev, pager, next"
                :total="followtotal">
            </el-pagination>
        </el-drawer>

        <!--退款-->
        <el-drawer
            title="退款记录"
            :visible.sync="refunddrawer"
            :direction="refunddirection"
            :size="$_isMobile()?'90%':'50%'"
            @close="refunddrawerClosed">
            <el-form :model="refundForm" :rules="refundFormRules" ref="refundFormRef" label-width="100px">
                

                <el-form-item label="退款金额" prop="money" >
                    <el-input v-model="refundForm.money" placeholder="请输入退款金额"></el-input>
                </el-form-item>
                <el-form-item label="扣减线索数" prop="count" >
                    <el-input v-model="refundForm.count" placeholder="请输入此次退款扣减的线索数量"></el-input>
                </el-form-item>
                <!-- v-show="false" hidden="true"-->
                <!-- <el-form-item label="uId" prop="uId">
                    <el-input placeholder="请输入内容" v-model="followForm.uId" :disabled="true"></el-input>
                </el-form-item> -->
                <el-form-item label="eId" prop="eId" v-show="false" hidden="true">
                    {{queryInfo.eId}}
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addRefund(queryInfo.eId)">提交</el-button>
                </el-form-item>
            </el-form>
            

            <el-table :data="refundList" style="width: 100%" border stripe  @selection-change="refundhandleSelectionChange"  ref="multipleTable2">
                <el-table-column prop="createTime" label="退款时间" ></el-table-column>
                <el-table-column prop="money" label="退款金额" ></el-table-column>
                <el-table-column prop="count" label="扣减线索数" ></el-table-column>
                <el-table-column prop="username" label="操作人" ></el-table-column>
            </el-table>

            <el-pagination
                v-if="$_isMobile()"
                background
                @size-change="refundhandleSizeChange"
                @current-change="refundhandleCurrentChange"
                :current-page="queryInfo.refundpageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.refundpageSize"
                layout=" prev, pager, next"
                :total="refundtotal">
            </el-pagination>
            <el-pagination
                v-else
                background
                prev-text="上一页"
                next-text="下一页"
                @size-change="refundhandleSizeChange"
                @current-change="refundhandleCurrentChange"
                :current-page="queryInfo.refundpageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.refundpageSize"
                layout="total, sizes, prev, pager, next"
                :total="refundtotal">
            </el-pagination>
        </el-drawer>

        <!--手动推送线索-->
        <el-dialog
            title="手动推送线索"
            :visible.sync="handAddDialogVisible"
            :width="$_isMobile()?'90%':'50%'"
            @close="handAddDialogClosed"
            :close-on-click-modal="false">
            <!--主题内容区-->
            <el-form :model="handAddForm" :rules="handAddFormRules" ref="handAddFormRef" label-width="80px">
                <el-form-item label="姓名" prop="name" >
                    <el-input v-model="handAddForm.name" placeholder="请输入线索姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone" >
                    <el-col :span="16">
                        <el-input v-model="handAddForm.phone" placeholder="手机号" style="width: 100%;"></el-input>
                    </el-col>
                </el-form-item>
                <!-- {{handAddForm.regionCode}}
                {{handAddForm.regionCodeList}}
                {{handAddForm.regionCode2}} -->
                <el-form-item label="所在地" prop="regionCodeList">
                    <el-cascader ref="handcascader" v-model="handAddForm.regionCodeList" :options="cityList" :show-all-levels="false" :props="props2" clearable @change="handAddFormchangeCityValue()"></el-cascader>
                    * 跑市的客户可不用选择，系统会自动匹配，跑省的必须选择
                </el-form-item>
                <el-form-item label="科室" prop="departmentId">
                    <!-- @change="handAddFormchangeDepartmentIdValue()"-->
                    <el-select v-model="handAddForm.departmentId" placeholder="请选择科室" label="科室" clearable>
                        
                        <el-option
                            v-for="item in departmentIdScopeByEid"
                            :key="item.id"
                            :label="item.deptName"
                            :value="item.id">
                        </el-option>
                    </el-select> 
                </el-form-item>
                  
                <el-form-item label="QQ" prop="qq" >
                    <el-input v-model="handAddForm.qq" placeholder="请输入线索QQ号"></el-input>
                </el-form-item>
                <el-form-item label="年龄" prop="age" >
                    <el-input v-model="handAddForm.age" placeholder="请输入线索年龄"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="gender" >
                    <el-radio v-model="handAddForm.gender" label="男">男</el-radio>
                    <el-radio v-model="handAddForm.gender" label="女">女</el-radio>
                </el-form-item>
                <el-form-item label="来源" prop="app_name" >
                    <el-radio v-model="handAddForm.app_name" label="字节-抖音">字节-抖音</el-radio>
                    <el-radio v-model="handAddForm.app_name" label="快手">快手</el-radio>
                    <el-radio v-model="handAddForm.app_name" label="朋友圈">朋友圈</el-radio>
                </el-form-item>
                
                
                <!-- v-show="false" hidden="true"-->
                <el-form-item label="enterpriseIds" prop="enterpriseIds" v-show="false" hidden="true">
                    {{handAddForm.enterpriseIds}}
                </el-form-item>
                <el-form-item label="regionCode" prop="regionCode" v-show="false" hidden="true">
                    {{handAddForm.regionCode}}
                </el-form-item>
            </el-form>
            <!--底部区-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="handAddDialogVisible = false">取 消</el-button>
                <el-button type="primary"  @click="handAddUser()">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data(){
        const validatePrice = (rule,value,callback) =>{
            let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
            ///^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/
            if(!value){
                callback(new Error('充值金额不能为空'))
            }else if(!reg.test(value)){
                callback(new Error('请输入正确格式的充值金额'))
                // this.$set(this.addFormRules, "price", '');
            }else if(value.length > 20){
                callback(new Error('最多可输入20个字符'))
                // this.$set(this.addFormRules, "price", '');
            }else{
            callback();
            }
        }
        const validateNumber = (rule,value,callback) =>{
                //let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$/
                let reg = /^([1-9][0-9]*)$/
                if(value!="undefined" && value!=null && value!=""){
                    if(!reg.test(value)){
                        callback(new Error('请输入正确格式'))
                        //this.$set(this.addFormRules, "price", '');
                    }else if(value.length > 20){
                        callback(new Error('最多可输入20个字符'))
                        //this.$set(this.addFormRules, "price", '');
                    }else{
                    callback();
                    }
                }else{
                    callback();
                }
            }
        
        return{
            roles: [],
            //获取用户列表的参数对象
            queryInfo:{
                username:'',
                status:'',
                lastSelectRoleList:'',
                createTime:'',
                pageNo:0,//当前页数
                pageSize:10,//当前每页显示多少条


                followpageNo:0,//充值记录 当前页数
                followpageSize:10,//充值记录 当前每页显示多少条

                refundpageNo:0,//退款记录 当前页数
                refundpageSize:10,//退款记录 当前每页显示多少条

                eId:'',

                surplusCount:'',//剩余数量筛选
                departmentId:''//科室筛选
            },

            cityList:[],
            cityValue:[], // 值
            typeList:[],

            userList:[],
            total:0, //共多少条数据
            addDialogVisible:false,//控制对话框的显示与隐藏
            addForm:{
                id:'',
                realname:'',
                username:'',
                smscode:'',
                phone:'',
                selectRoleList:[],
                lastSelectRoleList:'',
                status:1,
                remarks:'',

                password:'',

                beginTime:'',
                endTime:'',


                regionCode:'',
                regionCodeList:[],
                departmentId:'',
                departmentList:[],

                source:'',//渠道来源
            },//添加sys用户的表单数据

            followForm:{//充值
                count:0,
                money:0,
                eId:'',
            },

            refundForm:{//退款
                count:0,
                money:0,
                eId:'',
            },



            departmentIdScope:[],
            departmentIdScopeByEid:[],
            creatTime:[],
            rolesList:[],//添加弹出框
            rolesListSearch:[],//搜索条件
            addFormRules:{//验证规则
                hospitalName:[
                    {required:true,message:'请输入医院名称',trigger:'blur'},
                    {min:2,max:30,message:'医院名称在2-30之间',trigger:'blur'}
                ],
                username:[
                    {required:true,message:'请输入登录账号',trigger:'blur'},
                    {min:5,max:20,message:'登录账号在5-20之间',trigger:'blur'}
                ],
                regionCodeList:[
                    {required:true,message:'请选择区域范围',trigger:'blur'},
                ],
                departmentList:[
                    {required:true,message:'请选择科室',trigger:'blur'},
                ],
            },
            addFormRef:{},

            followFormRules:{//验证规则
                count:[ 
                    {required:true,message:'请输入此次的分配的线索数量',trigger:'blur'},
                    {validator:validateNumber,trigger:'blur'}
                ],
                money:[
                    {required:true,message:'请输入充值金额',trigger:'blur'},
                    {validator:validatePrice,trigger:'blur'}
                ],
            },
            followFormRef:{},

            refundFormRules:{//验证规则
                count:[ 
                    {required:true,message:'请输入此次的扣减的线索数量',trigger:'blur'},
                    {validator:validateNumber,trigger:'blur'}
                ],
                money:[
                    {required:true,message:'请输入退款金额',trigger:'blur'},
                    {validator:validatePrice,trigger:'blur'}
                ],
            },
            refundFormRef:{},

            handAddDialogVisible:false,//控制对话框的显示与隐藏
            handAddForm:{
                enterpriseIds:'',

                regionCode:'',//医院自带的
                regionCodeList:[],
                departmentId:'',
                departmentList:[],
                regionCode2:'',//用户选择的
            },
            handAddFormRules:{//验证规则
                name:[ 
                    {required:true,message:'请输入姓名',trigger:'blur'},
                    {min:1,max:10,message:'请输入正确的姓名',trigger:'blur'}
                ],
                departmentId:[
                    {required:true,message:'请选择科室',trigger:'blur'},
                ],
                phone:[
                    {required:true,message:'请输入手机号',trigger:'blur'},
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                app_name:[
                     {required:true,message:'请选择来源',trigger:'blur'},
                ]
            },
            handAddFormRef:{},

            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    var end = new Date();
                    var start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    //start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
                    //end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
                    picker.$emit('pick', [start,end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {

                    var end = new Date();
                    var start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    //start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
                    //end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
                    picker.$emit('pick', [start,end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    var end = new Date();
                    var start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    //start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
                    //end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
                    picker.$emit('pick', [start,end]);
                    }
                }]
            },


            addOReditDialogVisible:0, //0是add，1是edit

            multipleSelection: [], //全选按钮


            //验证码倒计时
            timer:null,
            countdown:60,
            codeMsg:'获取短信验证码',
		    codeDisabled:true,


            props:{
                value: 'code',
                label: 'name',
                children: 'districtCodeDtos',
                checkStrictly: true,
                
            },
            props2:{//手动推送，选择城市
                value: 'code',
                label: 'name',
                children: 'districtCodeDtos',
                checkStrictly: false,
                
            },

            //充值记录 抽屉
            drawer: false,
            direction: 'rtl',

            followList:[],
            followtotal:0,

            //退款记录 抽屉
            refunddrawer: false,
            refunddirection: 'rtl',

            refundList:[],
            refundtotal:0,


        }
    },
    created(){
        this.getUserList()
        this.getRoleListSearch()
        this.getCityList()
        this.getDepartmentList()
    },
    methods:{
        async getUserList(){
            //console.log("????????");
           const {data:res} = await this.$http.get('sys/user/list',{params:this.queryInfo});
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取用户列表失败')
           this.userList = res.result.records;
           this.total = res.result.total;
        },
        async getFollowList(eId){
           console.log("充值记录表");
           this.drawer = true;
           this.queryInfo.eId = eId;//放到查询条件里     企业id
           const {data:res} = await this.$http.get('crm/rechargerecord/list',{params:this.queryInfo});
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取充值记录列表失败')
           this.followList = res.result.records;
           this.followtotal = res.result.total;
        },

        async getRefundList(eId){
           console.log("退款记录表");
           this.refunddrawer = true;
           this.queryInfo.eId = eId;//放到查询条件里     企业id
           const {data:res} = await this.$http.get('crm/refundrecord/list',{params:this.queryInfo});
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取退款记录列表失败')
           this.refundList = res.result.records;
           this.refundtotal = res.result.total;
        },
        
        //监听pagesize改变事件
        handleSizeChange(newSize){
            console.log(newSize);
            this.queryInfo.pageSize = newSize;
            this.getUserList();
        },
        //监听页码值改变的事件
        handleCurrentChange(newPage){
            console.log(newPage);
            this.queryInfo.pageNo = newPage;
            this.getUserList();
        },
        //监听pagesize改变事件
        followhandleSizeChange(follownewSize){
            console.log(follownewSize);
            this.queryInfo.followpageSize = follownewSize;
            console.log("followhandleSizeChange-followForm.uId:"+this.followForm.eId);
            this.getFollowList(this.queryInfo.eId);
        },
        //监听页码值改变的事件
        followhandleCurrentChange(follownewPage){
            console.log(follownewPage);
            this.queryInfo.followpageNo = follownewPage;
            this.getFollowList(this.queryInfo.eId);
        },
        //监听pagesize改变事件
        refundhandleSizeChange(refundnewSize){
            console.log(refundnewSize);
            this.queryInfo.refundpageSize = refundnewSize;
            console.log("refundhandleSizeChange-refundForm.uId:"+this.refundForm.eId);
            this.getRefundList(this.queryInfo.eId);
        },
        //监听页码值改变的事件
        refundhandleCurrentChange(refundnewPage){
            console.log(refundnewPage);
            this.queryInfo.refundpageNo = refundnewPage;
            this.getRefundList(this.queryInfo.eId);
        },
        //搜索 状态下拉
        selectStatus(status){
            console.log(status+"~~~~~~~~~~~");
            this.queryInfo.status = status;
            this.searchBtn();
        },
        //搜索 科室下拉
        selectDepartmentId(departmentId){
            console.log(departmentId+"~~~~~~~~~~~");
            this.queryInfo.departmentId = departmentId;
            this.searchBtn();
        },
        //搜索 剩余数量下拉
        selectSurplusCount(surplusCount){
            console.log(surplusCount+"~~~~~~~~~~~");
            this.queryInfo.surplusCount = surplusCount;
            this.searchBtn();
        },
        //搜索 角色下拉
        selectRoleList(lastselectRoleList){
            console.log(lastselectRoleList+"~~~~~~~~~~~");
            //this.queryInfo.lastselectRoleList = lastselectRoleList;
            this.searchBtn();
        },
        //搜索条件 创建时间段
        selectCreatTime(creatTime){
            if(creatTime==null) {
                this.creatTime = [];
                this.queryInfo.beginTime = '';
                this.queryInfo.endTime = '';
            }else{
                this.queryInfo.beginTime = creatTime[0];
                this.queryInfo.endTime = creatTime[1];
            }

            console.log(creatTime+"~~~~~~~~~~~");
            this.searchBtn();
        },

        searchBtn(){
            this.queryInfo.pageNo=1
            this.getUserList()
        },
        //监听switch开关的状态
        async usersStateChanged(userinfo){
            console.log(userinfo);
            const {data:res} = await this.$http.put('sys/user/frozenBatch',{ids:userinfo.id,status:userinfo.status});
            if(res.code !== 200){
                userinfo.status = !userinfo.status
                return this.$message.error('更新用户状态失败');
            }
            return this.$message.success('更新用户状态成功');
        },

        async frozenBatchUP(){
            const confirmResult = await this.$confirm('是否确认批量启用', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果确认删除，返回字符串confirm，如果点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消');
            }
            console.log('确认了启用');

            let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
            let idsList = [];
            checkArr.forEach(function (item) {
                idsList.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
            });

            let ids = idsList.join(',');
            console.log(ids);
            //return this.$message.info('暂停一下！');
            if(!ids){
                return this.$message.info('请选择要更新状态的用户');
            }

            const {data:res} = await this.$http.put('sys/user/frozenBatch',{ids:ids,status:1});
            if(res.code !== 200){
                //userinfo.status = !userinfo.status
                return this.$message.error('批量更新用户状态失败');
            }
            this.$message.success('批量更新用户状态成功');
            this.getUserList();//重新获取列表数据

        },
        async frozenBatchDown(){
            const confirmResult = await this.$confirm('是否确认批量禁用', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果确认删除，返回字符串confirm，如果点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消');
            }
            console.log('确认了禁用');

            let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
            let idsList = [];
            checkArr.forEach(function (item) {
                idsList.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
            });

            let ids = idsList.join(',');
            console.log(ids);
            //return this.$message.info('暂停一下！');
            if(!ids){
                return this.$message.info('请选择要更新状态的用户');
            }

            const {data:res} = await this.$http.put('sys/user/frozenBatch',{ids:ids,status:2});
            if(res.code !== 200){
                //userinfo.status = !userinfo.status
                return this.$message.error('批量更新用户状态失败');
            }
            this.$message.success('批量更新用户状态成功');
            this.getUserList();//重新获取列表数据

        },
        //批量删除
        //promis用于异步操作，asyne／await语法糖将异步操作的同步写法 根then用法是一样的 还避免了回调地狱
        //将同步写异步代码，代码会采用非阻塞的方式继续执行下去，解决回调地狱的问题
        async removeByIds(){
            //询问是否删除
            const confirmResult = await this.$confirm('是否确认删除选中的用户？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果用户确认删除，返回字符串confirm，如果用户点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消删除');
            }
            console.log('确认了删除');

            let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
            let idsList = [];
            checkArr.forEach(function (item) {
                idsList.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
            });

            let ids = idsList.join(',');
            console.log(ids);
            if(!ids){
                return this.$message.info('请选择要删除的用户');
            }

            const {data:res} = await this.$http.delete('sys/user/deleteBatch?ids='+ids);
            if(res.code !== 200) {
                //少一个return？
                return this.$message.error(res.message);
            }
            this.$message.success('批量删除成功');
            console.log("批量删除成功");
            this.getUserList();//重新获取菜单列表数据
            console.log("刷新列表");
        },
        //推送消息
        async pushByIds(){
            const promptResult = await this.$prompt('请输入消息内容', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: '',
                inputErrorMessage: '格式不正确'
            }).catch(err => err)

            //如果banner确认删除，返回字符串confirm，如果banner点击取消，则返回值魏字符串cancel
            console.log("…………………………")
            console.log(promptResult)
            if(promptResult.action !== 'confirm'){
                return this.$message.info('已经取消');
            }
            console.log('确认了推送');
            if(promptResult.value == null){
                return this.$message.info('请输入消息内容');
            }
            if(promptResult.value.length>500){
                return this.$message.info('不能大于500字');
            }

            this.addForm.opinionValue = promptResult.value



            let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
            let idsList = [];
            checkArr.forEach(function (item) {
                idsList.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
            });
            let ids = idsList.join(',');
            console.log(ids);
            if(!ids){
                return this.$message.info('请选择消息推送对象');
            }
            const {data:res} = await this.$http.post('sys/user/push',{ids:ids,msg:promptResult.value});
            if(res.code !== 200){
                return this.$message.error('推送消息失败');
            }
            return this.$message.success('推送消息成功');

            // const {data:res} = await this.$http.delete('sys/user/deleteBatch?ids='+ids);
            // if(res.code !== 200) {
            //     //少一个return？
            //     return this.$message.error(res.message);
            // }
            // this.$message.success('批量删除成功');
            // console.log("批量删除成功");
            // this.getUserList();//重新获取菜单列表数据
            // console.log("刷新列表");
        },

        async getRoleListSearch(){
            const {data:res} = await this.$http.get('sys/role/queryall');

            console.log(res);
            if(res.code !== 200){
                return this.$message.error('获取角色失败');
            }
            //console.log(res.result);
            this.rolesListSearch = res.result
        },

        //显示添加用户的对话框
        async showAddDialog(){
            //const {data:res} = await this.$http.get('sys/user/queryUserRoleName?userid='+JSON.parse(window.sessionStorage.getItem('userInfo')).id);
            const {data:res} = await this.$http.get('sys/role/queryall');

            console.log(res);
            if(res.code !== 200){
                return this.$message.error('获取角色失败');
            }
            //console.log(res.result);
            this.rolesList = res.result
            this.addDialogVisible = true
        },

        //监听用户添加Dialog关闭事件
        addDialogClosed(){
            this.$refs.addFormRef.resetFields()
            this.addOReditDialogVisible=0
        },
        //监听充值记录抽屉关闭事件
        drawerClosed(){
            this.$refs.followFormRef.resetFields()
            this.drawer=false
            this.queryInfo.eId=''
            this.getUserList()
        },
        //监听充值记录抽屉关闭事件
        refunddrawerClosed(){
            this.$refs.refundFormRef.resetFields()
            this.refunddrawer=false
            this.queryInfo.eId=''
            this.getUserList()
        },
        //显示手动添加客户的对话框
        async showHandAddDialog(eid,regionCode){
            //const {data:res} = await this.$http.get('sys/user/queryUserRoleName?userid='+JSON.parse(window.sessionStorage.getItem('userInfo')).id);
            // const {data:res} = await this.$http.get('sys/role/queryall');

            // console.log(res);
            // if(res.code !== 200){
            //     return this.$message.error('获取角色失败');
            // }
            // //console.log(res.result);
            // this.rolesList = res.result
            this.handAddForm.enterpriseIds = eid
            this.handAddForm.regionCode=regionCode//医院自带的
            const {data:res} = await this.$http.get('crm/department/getByEnterpriseId?eid='+eid);
            // console.log(res);
            // if(res.code !== 200){
            //     return this.$message.error('获取科室失败');
            // }
            this.departmentIdScopeByEid=res.result
            this.handAddDialogVisible = true
        },

        //监听手动客户添加Dialog关闭事件
        handAddDialogClosed(){
            this.$refs.handAddFormRef.resetFields()
            this.handAddForm.regionCode=''//医院自带的
            this.handAddForm.regionCode2=''//用户输入的

        },
        async getCityList(){
            //const {data:res} = await this.$http.get('sys/common/regionListAll');
            const {data:res} = await this.$http.get('sys/common/regionList');
           console.log("||||||||||||||");
           console.log(res);
           //this.cityList = res.result;
           this.cityList = this.getTreeData(res);
            console.log("this.cityList");
           console.log(this.cityList);
            
        },
        // 递归判断列表，把最后的children设为undefined
        getTreeData(data){
            for(var i=0;i<data.length;i++){
                if(!data[i].districtCodeDtos){
                // children若为空数组，则将children设为undefined
                console.log("付值undefined");
                data[i].districtCodeDtos=undefined;
                }else {
                // children若不为空数组，则继续 递归调用 本方法
                this.getTreeData(data[i].districtCodeDtos);
                }
            }
            console.log(data);
            return data;
        },
        changeCityValue(){
            let nodevalue = this.$refs['cascader'].getCheckedNodes();
            console.log("<><><>><><><><><><><");
            if(nodevalue[0]==null||nodevalue[0]=="undefined"){
                this.addForm.regionCode = '';
                this.addForm.regionCodeList = [];
                //console.log(nodevalue[0].value);
                console.log("nodevalue[0].value 是空的");
            }else{
                this.addForm.regionCode = nodevalue[0].value;
                //this.addForm.regionCodeList = JSON.parse(this.addForm.regionCode);
            }
            this.$forceUpdate();
            //this.addForm.regionCode = nodevalue[0].value;
            // this.addForm.regionCode = this.addForm.regionCodeList.join(',');
            this.$refs.cascader.dropDownVisible = false; //监听值发生变化就关闭它
        },
        handAddFormchangeCityValue(){
            let nodevalue = this.$refs['handcascader'].getCheckedNodes();
            console.log("<><><>><><><><><><><");
            if(nodevalue[0]==null||nodevalue[0]=="undefined"){
                this.handAddForm.regionCode2 = '';
                this.handAddForm.regionCodeList = [];
                //console.log(nodevalue[0].value);
                console.log("nodevalue[0].value 是空的");
            }else{
                this.handAddForm.regionCode2 = nodevalue[0].value;
                //this.addForm.regionCodeList = JSON.parse(this.addForm.regionCode);
            }
            this.$forceUpdate();
            //this.addForm.regionCode = nodevalue[0].value;
            // this.addForm.regionCode = this.addForm.regionCodeList.join(',');
            this.$refs.handcascader.dropDownVisible = false; //监听值发生变化就关闭它
        },
        changeDepartmentIdValue(){
        //     //console.log(scopeBusiness+"~~~~~~~~~~~");
        //     // this.queryInfo.type = type;
        //     //  this.farmmachinery.addForm.regionCode = nodevalue[0].value;
             //this.addForm.departmentIdList = JSON.parse(this.addForm.departmentId);
             this.addForm.departmentId = this.addForm.departmentList.join(',');
             //this.addForm.departmentId = JSON.stringify(this.addForm.departmentList);
             console.log("this.addForm.departmentList");
             console.log(this.addForm.departmentList);
             console.log("this.addForm.departmentId");
             console.log(this.addForm.departmentId);
             
        },
        // handAddFormchangeDepartmentIdValue(){
        // //     //console.log(scopeBusiness+"~~~~~~~~~~~");
        // //     // this.queryInfo.type = type;
        // //     //  this.farmmachinery.addForm.regionCode = nodevalue[0].value;
        //      //this.addForm.departmentIdList = JSON.parse(this.addForm.departmentId);
        //      this.handAddForm.departmentId = this.handAddForm.departmentList.join(',');
        //      console.log("this.handAddForm.departmentList");
        //      console.log(this.handAddForm.departmentList);
        //      console.log("this.handAddForm.departmentId");
        //      console.log(this.handAddForm.departmentId);
             
        // },
        async getDepartmentList(){
           const {data:res} = await this.$http.get('crm/department/getall');
           console.log("getDepartmentIdList");
           console.log(res);
           this.departmentIdScope = res.result;
        },

        addUser(){
             this.$refs.addFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求

                 //this.addForm.lastSelectRoleList = this.addForm.selectRoleList.join(',');
                 //console.log(this.addForm.lastSelectRoleList);
                 console.log(this.addForm);
                 const {data:res} = await this.$http.post('sys/user/add',this.addForm);
                 console.log("+++++++addUser+++++++");
                  console.log(res);

                //  if(res.code !== 201) {
                //      //少一个return？
                //      return this.$message.error('添加用户失败');
                //  }
                 if(res.code == 500) {
                     return this.$message.error(res.message);
                 }

                 this.$message.success('添加用户成功');
                 this.addDialogVisible = false;//关闭添加用户的窗口
                 this.getUserList();//从新获取用户列表数据
             })
        },

        addFollow(eId){
             this.$refs.followFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求
                 console.log("直接取followForm的值"+this.followForm);
                 console.log("调用addFollow传过来的"+eId);
                 this.followForm.eId=eId;
                 console.log("uId负值给followForm之后的值"+this.followForm.eId);
                 console.log(this.followForm);
                 const {data:res} = await this.$http.post('crm/rechargerecord/add',this.followForm);
                 console.log("+++++++充值+++++++");
                  console.log(res);

                //  if(res.code !== 201) {
                //      //少一个return？
                //      return this.$message.error('添加用户失败');
                //  }
                 if(res.code == 500) {
                     return this.$message.error(res.message);
                 }

                 this.$message.success('充值成功');
                 this.getFollowList(eId);//从新获取充值记录列表数据
                 this.followForm.money=0;
                 this.followForm.count=0;

             })
        },

        addRefund(eId){
             this.$refs.refundFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求
                 console.log("直接取refundForm的值"+this.refundForm);
                 console.log("调用addrefund传过来的"+eId);
                 this.refundForm.eId=eId;
                 console.log("uId负值给refundForm之后的值"+this.refundForm.eId);
                 console.log(this.refundForm);
                 const {data:res} = await this.$http.post('crm/refundrecord/add',this.refundForm);
                 console.log("+++++++退款+++++++");
                  console.log(res);

                //  if(res.code !== 201) {
                //      //少一个return？
                //      return this.$message.error('添加用户失败');
                //  }
                 if(res.code == 500) {
                     return this.$message.error(res.message);
                 }

                 this.$message.success('退款成功');
                 this.getRefundList(eId);//从新获取充值记录列表数据
                 this.refundForm.money=0;
                 this.refundForm.count=0;

             })
        },

        //显示编辑用户的对话框
        async showEditDialog(id){
            console.log(id);
            this.addOReditDialogVisible=1;
            const {data:res} = await this.$http.get('sys/user/queryById/?id='+id);//sys用户id
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('查询用户失败');
            }

            const {data:res2} = await this.$http.get('sys/common/regionPid/?code='+res.result.records[0].regionCode);
            console.log(res2);
            
            console.log("pid>>>>>>>>>>"+res2.result[0].pid);
            //问题的本质是因为你编辑时，第一次打开dialog的时候给表单绑定的form赋值了，这时候这个form的初始值就变成了你所赋值的值，所以resetFields的时候，会将form对应的每个值重置到初始值，这时候的初始值就是你编辑时赋值的那个值, 而不是在data里声明的初始值，
            //解决方式是，等dialog已经初始化之后再给form赋值，也就是
            this.addDialogVisible = true
            this.$nextTick(() => {
                //this.addForm = res.result.records
                this.addForm = res.result.records[0]
                console.log("showEditDialog");
                console.log(res);
                //this.addForm.regionCodeList = JSON.parse(res.result.records[0].regionCode);
                console.log("res2.result[0].pid"+res2.result[0].pid);
                console.log("res.result.records[0].regionCode"+res.result.records[0].regionCode);
                this.addForm.regionCodeList = JSON.parse("[ "+res2.result[0].pid+","+res.result.records[0].regionCode+" ]");
                //this.addForm.regionCodeList = JSON.parse(res.result.records[0].regionCode);
                console.log("regionCodeList");
                console.log(this.addForm.regionCodeList);

                this.addForm.departmentList = res.result.records[0].departmentList.split(",");
                
                console.log("departmentList");
                console.log(this.addForm.departmentList);
                this.addForm.departmentId = this.addForm.departmentList.join(',');

                this.addForm.source = 
                
                this.$forceUpdate();
            })


        },

        editUser(){
             this.$refs.addFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求

                 //this.addForm.lastSelectRoleList = this.addForm.selectRoleList.join(',');
                 //console.log(this.addForm.lastSelectRoleList);
                 const {data:res} = await this.$http.put('sys/user/edit',this.addForm);
                 console.log("+++++++editUser+++++++");
                  console.log(res);
                //  if(res.code !== 201) {
                //      //少一个return？
                //      return this.$message.error('添加用户失败');
                //  }
                 if(res.code == 500) {
                     return this.$message.error(res.message);
                 }

                 this.$message.success('修改用户成功');
                 this.addDialogVisible = false;//关闭添加用户的窗口
                 this.getUserList();//从新获取用户列表数据
             })
        },

        //重置密码
        //promis用于异步操作，asyne／await语法糖将异步操作的同步写法 根then用法是一样的 还避免了回调地狱
        //将同步写异步代码，代码会采用非阻塞的方式继续执行下去，解决回调地狱的问题
        async resetPassword(id){
            //询问是否重置
            const confirmResult = await this.$confirm('此操作将重置还原该用户的初始密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果用户确认重置，返回字符串confirm，如果用户点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消重置');
            }
            console.log('确认了重置');
            const {data:res} = await this.$http.put('sys/user/resetPassword',{id:id});
            if(res.code !== 201) {
                //少一个return？
                return this.$message.error('用户密码重置还原失败');
            }
            this.$message.success('用户密码重置还原成功');
            //this.getUserList();//从新获取用户列表数据,重置密码不用重新获取也可以
        },


        //删除用户
        //promis用于异步操作，asyne／await语法糖将异步操作的同步写法 根then用法是一样的 还避免了回调地狱
        //将同步写异步代码，代码会采用非阻塞的方式继续执行下去，解决回调地狱的问题
        async removeUserById(id){
            //询问是否删除
            const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果用户确认删除，返回字符串confirm，如果用户点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消删除');
            }
            console.log('确认了删除');
            const {data:res} = await this.$http.delete('sys/user/delete?id='+id);
            if(res.code !== 200) {
                //少一个return？
                return this.$message.error('删除用户失败');
            }
            this.$message.success('删除用户成功');
            this.getUserList();//从新获取用户列表数据
        },

        async exportById(id){
            //询问是否导出
            const confirmResult = await this.$confirm('此操作将导出该医院的所有线索, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果用户确认重置，返回字符串confirm，如果用户点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消');
            }
            console.log('确认了导出');
            const {data:res} = await this.$http.get('crm/user/exportById?hospitalId='+id,{responseType: 'blob'});//医院id
            console.log("导出excel");
            console.log(res);
            let blob = new Blob([res], {type: "application/x-www-form-urlencoded"}); // 为blob设置文件类型，这里以.xlsx为例
            let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
             console.log("url");
             //console.log(this.$_has);
             console.log(this.$_getNowFormatDate());
            let a = document.createElement("a");

            a.download = "线索导出数据-"+this.$_getNowFormatDate()+".xlsx"
            a.href = url;
            a.click();
            // 释放这个临时的对象url
            window.URL.revokeObjectURL(url); 
        },

        toggleSelection(rows) {
            if (rows) {
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
            });
            } else {
            this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        refundhandleSelectionChange(val) {
            this.multipleSelection = val;
        },

        handAddUser(){
            //console.log("+++++++handAddUser+++++++");
             this.$refs.handAddFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求

                 
                 console.log(this.handAddForm);
                 const {data:res} = await this.$http.post('crm/user/handadd',this.handAddForm);
                 console.log("+++++++handaddUser+++++++");
                  console.log(res);

                //  if(res.code !== 201) {
                //      //少一个return？
                //      return this.$message.error('添加用户失败');
                //  }
                 if(res.code == 500) {
                     return this.$message.error(res.message);
                 }
                 if(res.code == 201) {
                     return this.$message.error(res.message);
                 }

                 this.$message.success(res.message);
                 this.handAddDialogVisible = false;//关闭添加用户的窗口
                 this.getUserList();//从新获取用户列表数据 剩余可分配用户数-1
             })
        },
        
       //监听switch开关的状态 1正常 2暂停
        async enterpriseStateChanged(enterpriseinfo){
            console.log(enterpriseinfo);
            const {data:res} = await this.$http.put('crm/enterprise/frozenBatch',{ids:enterpriseinfo.providerCode,status:enterpriseinfo.status});
            if(res.code !== 200){
                enterpriseinfo.status = !enterpriseinfo.status
                return this.$message.error('更新状态失败');
            }
            return this.$message.success('更新状态成功');
        },

    }
}
</script>

<style lang="less" scoped>
//     /* 选中某行时的背景色*/
// .el-table__body tr.current-row > td {
//    background-color: #5ab2ec !important;
// }
 
// /*鼠标移入某行时的背景色*/
// .el-table--enable-row-hover .el-table__body tr:hover > td {
//    background-color: #5ab2ec;
// }
/deep/ .el-table tbody tr:hover>td { background-color: rgb(217, 236, 255) !important;}
.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: 300px;
}
.el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
}

/deep/ .el-drawer{
    background-color: #fff;
}
</style>
